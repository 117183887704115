import { lazy, Suspense } from 'react'
import { Box } from '@common/components'
import { Outlet } from '@lib/router'
import { CssBaseline, GlobalStyles, ThemeProvider } from '@common/utils/styles'
import Footer from '../footer'
import lightThemeUnsw from '@styles/themes/unsw-light-theme'
import HeaderPlaceholder from '../header/placeholder'
import ModulesChecker from '@features/modules-checker'

const Header = lazy(() => import('../header'))

const AppLayout: React.FC = () => {
  return (
    <ThemeProvider theme={lightThemeUnsw}>
      <GlobalStyles
        styles={{
          body: {
            overflowY: 'scroll',
          },
        }}
      />
      <CssBaseline />
      <Suspense fallback={<HeaderPlaceholder />}>
        <Header />
      </Suspense>
      <Box minHeight='calc( 100vh - 66px )'>
        <Outlet />
      </Box>
      <Footer />
      {/* <ModulesChecker /> */}
    </ThemeProvider>
  )
}

export default AppLayout
